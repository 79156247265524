import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ScrollShadow,
  Avatar,
  Select as SelectNextUi,
  SelectItem,
  Switch,
  Input,
} from "@nextui-org/react";
import Select from "react-select";
import { GlobeIcon } from "../../assets/icons/GlobeIcon";
import { useTranslation } from "react-i18next";
import { useShareFileModalStore } from "src/store/layout/useShareFileModalStore";
import useListAllShares from "src/hooks/useListAllShares";
import { usePublicShareLink } from "src/hooks/usePublicShareLink";
import useSaveShare from "src/hooks/useSaveShare";
import { PUBLIC_URL_DEMO } from "src/services/Endpoints";
import useListAllNotifications from "src/hooks/useListAllNotifications";
import { useLastThreeNotifications } from "src/hooks/useListLastThreeNotifications";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";

interface ShareFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (names: string[], roles: string[]) => void;
  onCopyLink: () => void;
  resourceId: string;
  resourceName: string;
  resourceType: "file";
}




const ShareFileModal: React.FC<ShareFileModalProps> = ({
  isOpen,
  onClose,
  onSave,
  resourceId,
  resourceName,
  resourceType,
}) => {
  const { t } = useTranslation();
  const { listAllShares, nonSharedUsers, insideSharedUsers, outsideSharedUsers, outsideSharedLink, expiration_date } = useListAllShares();
  const [hasFetched, setHasFetched] = useState(false);
  const [isPublicAccess, setIsPublicAccess] = useState(false);
  const [publicAccessFetching, setIsPublicAccessFetching] = useState(false);
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const { publicShareLink, fetchPublicShareLink } = usePublicShareLink({ resourceType, resourceId });
  const [combinedSharedUsers, setCombinedSharedUsers] = useState<any[]>([]); // Manage combined shared users state
  const [updatedNameOptions, setUpdatedNameOptions] = useState<any[]>([]);
  const { names, roles, setNames, setRoles, reset } = useShareFileModalStore();

  //save share
  const { saveShare } = useSaveShare();
  const { listAllNotificationsItems, currentPage, rowsPerPage } = useListAllNotifications();
  const { listLastThreeNotifications } = useLastThreeNotifications();
  const { refreshListAllShareByUser, shareByCurrentPage, rowsPerPage: sharedByRowsPerPage  } = useRefreshListAllSharedByMe();

  useEffect(() => {

    if (isOpen && resourceId && !hasFetched) {
      listAllShares("file", resourceId).then(() => setHasFetched(true));

      if (outsideSharedUsers) {
        setIsPublicAccess(true);
        setExpirationDate(expiration_date)
        setIsPublicAccessFetching(false);
      }
      else {
        setIsPublicAccess(false);
        setExpirationDate(null)
        setIsPublicAccessFetching(false);
      }

      if (insideSharedUsers) {
        setCombinedSharedUsers(insideSharedUsers);
      }

    }
    if (!isOpen) {
      setHasFetched(false);
      setCombinedSharedUsers([]); // Clear combinedSharedUsers when modal closes

    }
  }, [isOpen, resourceId, listAllShares, hasFetched, outsideSharedUsers, insideSharedUsers, expiration_date]);

  useEffect(() => {
    // Update nameOptions to exclude already shared users
    if (nonSharedUsers && combinedSharedUsers) {
      const combinedSharedUserIds = combinedSharedUsers.map(user => user.user_id);
      const updatedOptions = (nonSharedUsers || []).filter(user => !combinedSharedUserIds.includes(user.user_id)).map(user => {
        const name = user.full_name_en ? user.full_name_en : (user.full_name_ar ? user.full_name_ar : user.username);
        const user_id_staff = user.user_id_saff != null ? ' - ' + user.user_id_saff : '';
        return {
          value: user.user_id,
          label: `${name} ${user_id_staff}`
        };
      });
      setUpdatedNameOptions(updatedOptions);
    }
  }, [combinedSharedUsers, nonSharedUsers]);





  // Default role options
  const roleOptions = [
    { value: "viewer", label: t("Viewer") },
    { value: "editor", label: t("Editor") }
  ];
  const permissionOptions = [
    { value: "viewer", label: t("Viewer") },
    { value: "editor", label: t("Editor") },
    { value: "removeAccess", label: t("RemoveAccess") }
  ];

  const [copyMessage, setCopyMessage] = useState<string | null>(null);

  const handleCopyPublicLink = async () => {
    try {
      if (outsideSharedUsers && outsideSharedLink !== "") {
        await navigator.clipboard.writeText(`${PUBLIC_URL_DEMO}${outsideSharedLink}`);
        setCopyMessage(t("CopiedPublicLink"));

      }
      else if (publicAccessFetching) {
        await navigator.clipboard.writeText(`${PUBLIC_URL_DEMO}${publicShareLink}`);
        setCopyMessage(t("CopiedPublicLink"));
      }
      else
        if (!publicAccessFetching) {
          await fetchPublicShareLink(expirationDate);//expirationDate
          setIsPublicAccessFetching(true);
          if (publicShareLink) {
            await navigator.clipboard.writeText(`${PUBLIC_URL_DEMO}${publicShareLink}`);
            setCopyMessage(t("CopiedPublicLink"));
          }
        }
    } catch (error) {
      setIsPublicAccessFetching(false);
      setCopyMessage(t("FailedCopiedPublicLink"));
    } finally {
      setTimeout(() => setCopyMessage(null), 3000);
    }
  };


  const handleSwitchChange = (checked: boolean) => {
    setIsPublicAccess(checked);
  };


  const handleSave = async () => {
    try {

      handleAdd(true);

      const sharePayload = combinedSharedUsers.map(user => ({
        shared_with_user_id: user.user_id,
        share_type: 'inside',
        permissions: user.permissions,
        expiration_date: expirationDate || null,
      }));

      // if (isPublicAccess && !publicAccessFetching && ( (!outsideSharedUsers && outsideSharedLink === "") || expirationDate !== expiration_date ) ) {

      if (isPublicAccess && ( (!outsideSharedUsers && outsideSharedLink === "") || expirationDate !== expiration_date ) ) {
        sharePayload.push({
          shared_with_user_id: null, // or some default value if required
          share_type: 'outside',
          permissions: 'viewer', // or any default permission you want to set
          expiration_date: expirationDate, //set the expiration_date

        });
      }
      else if (!isPublicAccess) {
        sharePayload.push({
          shared_with_user_id: null, // or some default value if required
          share_type: 'outside',
          permissions: 'removePublicAccess', // or any default permission you want to set
          expiration_date: null,
        });
      }

      await saveShare(resourceType, resourceId, sharePayload);
      await listAllNotificationsItems(currentPage, rowsPerPage); // Refresh the notifications after marking them as read
      await listLastThreeNotifications();
      await refreshListAllShareByUser(shareByCurrentPage, sharedByRowsPerPage);

      onSave(names, roles);
      reset();
      onClose();
    } catch (err) {
      console.error("Error saving shares: ", err);
    }
  };


  const handleRoleChange = (userId: string, selectedRole: string) => {
    setCombinedSharedUsers(prevUsers =>
      prevUsers.map(user =>
        user.user_id === userId ? { ...user, permissions: selectedRole } : user
      )
    );
  };

  const handleAdd = (withSave: boolean) => {

    // Find users to add
    const newUsers = updatedNameOptions.filter(option =>
      names.includes(option.value)
    );

    if (newUsers.length > 0) {
      // Check if roles are selected; if not, set default role to "Viewer"
      const newRole = roles.length > 0 ? roles[0] : "viewer"; // Default role is "viewer"

      const updatedUsers = newUsers.map(user => ({
        user_id: user.value,
        full_name_en: user.label.split(' - ')[0],
        permissions: newRole // Set permissions to the selected or default role
      }));

      if (withSave)
        combinedSharedUsers.push(...updatedUsers);
      else
        setCombinedSharedUsers(prevUsers => [
          ...prevUsers,
          ...updatedUsers
        ]);

      setNames([]);
      setRoles([]);
    }

  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "0.5rem",
      backgroundColor: "#F4F4F5",
      borderColor: "transparent",
      boxShadow: "none",
      marginTop: "1.25rem",
      "&:hover": {
        borderColor: "transparent",
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "white",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#73737C",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#73737C",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Modal scrollBehavior="inside" size="5xl" isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>{t("Share")} {resourceName}:</ModalHeader>
        <ModalBody>
          <form className="flex flex-col gap-4 pb-10">
            <div className="flex gap-3 max-sm:flex-col">
              <Select
                isMulti
                options={updatedNameOptions}
                value={updatedNameOptions.filter(option => names.includes(option.value))}
                onChange={(selectedOptions) =>
                  setNames(selectedOptions ? selectedOptions.map(option => option.value) : [])
                }
                placeholder={t("AddPeople")}
                styles={customStyles}
                className="w-1/3 max-sm:w-full"
              />
              <Select
                options={roleOptions}
                value={roleOptions.find((option) => option.value === roles[0])} // Only one value is allowed
                onChange={(selectedOption) =>
                  setRoles(
                    selectedOption ? [selectedOption.value] : [] // Set single value or empty array
                  )
                }
                placeholder={t("SelectRoles")}
                styles={customStyles}
                className="w-1/2 max-sm:w-full"
                isClearable // Allows the user to clear the selection
              />
              <Button className="mt-5" color="primary" onPress={() => handleAdd(false)}>
                {t("Add")}
              </Button>
            </div>

            <div className="text-lg mt-5">{t("PeopleWithAccess")}</div>
            <div>
              <ScrollShadow hideScrollBar className="w-full h-[250px]">
                <div className="flex flex-col justify-center items-center">
                  {/* user 1 the owner  */}
                  {/* <div className="flex justify-between w-[95%]">
                    <div className="flex gap-3">
                      <Avatar src="" size="md" />
                      <div className="flex flex-col">
                        <span>{ownerName} (You)</span>
                        <span className="text-gray-400 text-sm">{ownerId}</span>
                      </div>
                    </div>
                    <div>Owner</div>
                  </div> */}

                  {/* start insideSharedUsers loop */}

                  {combinedSharedUsers?.map((user) => {
                    return (
                      <div className="flex justify-between w-[95%] mt-3" key={user.user_id}>
                        <div className="flex gap-3">
                          <Avatar src="" size="md" />
                          <div className="flex flex-col">
                            <span>{user.full_name_en || user.full_name_ar || user.username}</span>
                            <span className="text-gray-400 text-sm">{user.user_id_saff}</span>
                          </div>
                        </div>
                        <div className="lg:w-1/6 max-lg:w-1/6 max-sm:w-1/2 max-md:w-1/3 md:w-1/3">
                          <SelectNextUi
                            label="Select a Role"
                            className="w-full"
                            size="sm"
                            key={user.permissions} // Ensure this matches one of the SelectItem values
                            value={user.permissions} // Ensure this matches one of the SelectItem values
                            placeholder={t(user.permissions.charAt(0).toUpperCase() + user.permissions.slice(1))} // Ensure this matches one of the SelectItem values
                            onChange={(e) => handleRoleChange(user.user_id, e.target.value)}

                          >
                            {permissionOptions.map((role) => (
                              <SelectItem key={role.value} value={role.value}>
                                {role.label}
                              </SelectItem>
                            ))}
                          </SelectNextUi>
                        </div>
                      </div>
                    );
                  })}

                  {/* end shared users loop */}


                </div>
              </ScrollShadow>


              <div className="text-lg mt-5 w-full flex justify-between">
                <div>
                  {t("PublicAccess")}&nbsp;
                  <span className="text-gray-400 text-sm">{copyMessage}</span>
                </div>
                <Switch
                  aria-label="Public Access Switch"
                  isSelected={isPublicAccess}
                  onChange={(e) => handleSwitchChange(e.target.checked)}
                />
              </div>
              {/* Conditionally rendered div */}
              {isPublicAccess && (
                <div className="flex flex-col justify-center items-center mt-3 max-sm:flex">
                  <div className="flex justify-between w-[95%] max-sm:flex-col">
                    <div className="flex gap-3">
                      <Avatar icon={<GlobeIcon />} size="sm" />
                      <div className="flex flex-col">
                        <span className="max-sm:text-sm">{t("ByCopyingTheLinkYouAgreeToShareItOutsideSaf")}</span>
                        <span className="text-red-400 text-sm max-sm:text-xs">{t("ByCopyingTheLinkYouAreResponsible")}</span>
                      </div>
                    </div>
                    <div>
                      <Button className="max-sm:w-full" color="secondary" onPress={handleCopyPublicLink}>
                        {t("CopyLink")}
                      </Button>
                    </div>
                  </div>

                  <div className="flex justify-between w-[95%] max-sm:flex-col mt-8">
                    <label className="max-sm:w-full"> {t("ExpirationDate")}  </label>
                    <Input
                      type="date"
                      value={expirationDate || ""}
                      onChange={(e) => setExpirationDate(e.target.value)}
                      className="text-sm w-1/3 max-sm:w-full"
                    />
                   </div>

                </div>
              )}

            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            {t("Close")}
          </Button>
          <Button color="primary" onPress={handleSave}>
            {t("Save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareFileModal;
