import { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import { LockIcon } from "../../assets/icons/LockIcon";
import { PhoneIcon } from "../../assets/icons/PhoneIcon";
import { useTranslation } from "react-i18next";
import { Alert, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSignInStore } from "src/store/api/useSignInStore";
import Captcha from "src/utils/Captcha";

export default function SignInModal() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { t } = useTranslation();
  const [, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState<string>("");
  const [generatedCaptcha, setGeneratedCaptcha] = useState<string>("");
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false);
  const [hasAlert, setHasAlert] = useState<boolean>(false);

  const {
    phoneNumber,
    password,
    googleAuthCode,
    step,
    imageGoogleAuthUrl,
    newPassword,
    confirmPassword,
    setPhoneNumber,
    setPassword,
    setGoogleAuthCode,
    setNewPassword,
    setConfirmPassword,
    setStep,
    handleSignIn,
    handleGoogleAuthSubmit,
    handleResetPassword,
    error,
    successMessage,
  } = useSignInStore();

  useEffect(() => {
    setIsCaptchaValid(captchaValue === generatedCaptcha);
  }, [captchaValue, generatedCaptcha]);

  useEffect(() => {
    setHasAlert(!!error || !!successMessage);
  }, [error, successMessage]);

  const validateAndSignIn = async () => {
    if (!phoneNumber) {
      message.error(t("EnterYourID"));
      return;
    }
    if (!password) {
      message.error(t("FillPassword"));
      return;
    }
    if (!isCaptchaValid) {
      message.error(t("Captcha does not match"));
      return;
    }

    try {
      await handleSignIn();
    } catch (err) {
      message.error(t("Sign in failed: ") + (error || t("Unknown error")));
    }
  };

  const validateAndSubmitGoogleAuth = async () => {
    if (!googleAuthCode) {
      message.error(t("FillGoogleAuthCode"));
      return;
    }

    try {
      await handleGoogleAuthSubmit(navigate);
    } catch (err) {
      message.error(t("Authentication failed: ") + (error || t("Unknown error")));
    }
  };

  return (
    <>
      {contextHolder}
      <Button
        className="bg-success text-background"
        size="lg"
        onPress={onOpen}
        color="primary"
      >
        {t("Signin")}
      </Button>

      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          onOpenChange();
          setStep(1);
        }}
        shouldBlockScroll={false}
        placement="center"
        classNames={{ wrapper: "items-start h-auto", base: "my-auto" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              {step === 1 && (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("Signin")}
                  </ModalHeader>

                  <ModalBody>
                    {error && (
                      <Alert message={error} type="error" showIcon closable />
                    )}

                    {successMessage && (
                      <Alert
                        message={successMessage}
                        type="success"
                        showIcon
                        closable
                      />
                    )}

                    <Input
                      autoFocus
                      required
                      classNames={{ input: "text-base" }}
                      endContent={
                        <PhoneIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      label={t("ID")}
                      placeholder={t("EnterYourID")}
                      variant="bordered"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />

                    <Input
                      endContent={
                        <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      classNames={{ input: "text-base" }}
                      required
                      label={t("Password")}
                      placeholder={t("EnterYourPassword")}
                      type="password"
                      variant="bordered"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <div className="flex py-2 px-1 justify-between max-sm:flex-col">
                      <Captcha onChange={setGeneratedCaptcha} />
                      <Input
                        classNames={{ input: "text-base" }}
                        required
                        variant="bordered"
                        placeholder={t("EnterCaptcha")}
                        value={captchaValue}
                        onChange={(e) => setCaptchaValue(e.target.value)}
                      />
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                      {t("Close")}
                    </Button>
                    <Button
                      color="primary"
                      onPress={validateAndSignIn}
                      disabled={!isCaptchaValid || hasAlert}
                    >
                      {t("Signin")}
                    </Button>
                  </ModalFooter>
                </>
              )}

              {step === 2 && (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("VerifyYourAccount")}
                  </ModalHeader>
                  <ModalBody>
                    {error && (
                      <Alert message={error} type="error" showIcon closable />
                    )}

                    {successMessage && (
                      <Alert
                        message={successMessage}
                        type="success"
                        showIcon
                        closable
                      />
                    )}

                    {imageGoogleAuthUrl && (
                      <div className="mb-4">
                        <img
                          src={imageGoogleAuthUrl}
                          alt="Google Authenticator QR Code"
                        />
                      </div>
                    )}
                    <Input
                      endContent={
                        <PhoneIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      autoFocus
                      classNames={{ input: "text-base" }}
                      type="number"
                      label={t("GoogleAuthCode")}
                      placeholder={t("EnterGoogleAuthCode")}
                      variant="bordered"
                      value={googleAuthCode}
                      onChange={(e) => setGoogleAuthCode(e.target.value)}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                      {t("Close")}
                    </Button>
                    <Button
                      color="primary"
                      onPress={validateAndSubmitGoogleAuth}
                    >
                      {t("Authenticate")}
                    </Button>
                  </ModalFooter>
                </>
              )}

              {step === 3 && (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {t("ResetPassword")}
                  </ModalHeader>
                  <ModalBody>
                    {error && (
                      <Alert message={error} type="error" showIcon closable />
                    )}

                    {successMessage && (
                      <Alert
                        message={successMessage}
                        type="success"
                        showIcon
                        closable
                      />
                    )}

                    <Input
                      required
                      label={t("NewPassword")}
                      placeholder={t("EnterNewPassword")}
                      type="password"
                      variant="bordered"
                      classNames={{ input: "text-base" }}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Input
                      required
                      label={t("ConfirmPassword")}
                      placeholder={t("ConfirmNewPassword")}
                      type="password"
                      variant="bordered"
                      classNames={{ input: "text-base" }}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                      {t("Close")}
                    </Button>
                    <Button color="primary" onPress={handleResetPassword}>
                      {t("ResetPassword")}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}