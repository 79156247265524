import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
 import { useEmptyTrash } from "src/hooks/useEmptyTrash";
 
interface EmptyTrashModalProps {
  isOpen: boolean;
  onClose: () => void;
 }

const EmptyTrashModal: React.FC<EmptyTrashModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { emptyTrash } = useEmptyTrash();
 
  const handleEmptyTrash = async () => {
    try {
      await emptyTrash();
       onClose();
    } catch (error) {
      console.error("Failed to empty trash:", error); // Improved error message
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("EmptyTrash")}
        </ModalHeader>
        <ModalBody>
          <h2>{t('AreYouSureEmptyTrash')}</h2>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleEmptyTrash}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmptyTrashModal;
