import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import {
  updateFolder,
  createFolder,
  deleteExpiredFolders,
  listAllFolders,
  copyFolder,
  // restoreFolder,
  // getFolderDetails,
  uploadFolder,
  listAllFoldersFiles,
  deleteFolderApi,
  emptyTrashFolder,
  listFolderContents,
  listPublicFolderContents,
  downloadFolder,
} from 'src/services/api/API';
import { useSidebarStore } from 'src/store/layout/useSidebarStore';
import { Item, SelectedFolder } from 'src/types/api/ListFolderFiles';
import { useFolderContentStore } from "src/components/main_page/cards/FolderCard";
import { useSharedWithFolderStore } from 'src/components/shared_files/cards/SharedWithMeFolderCard';
import useUserResourceStore from './useUserResourceStore';

interface FolderState {
  folderName: string;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isFetching: boolean;
  isCopying: boolean;
  isRestoring: boolean;
  isUploading: boolean;
  isDownloading: boolean;
  isEmptyingTrash: boolean;
  errorCreating: string | null;
  errorUpdating: string | null;
  errorDeleting: string | null;
  errorFetching: string | null;
  errorCopying: string | null;
  errorRestoring: string | null;
  errorUploading: string | null;
  errorDownloading: string | null;
  errorEmptyingTrash: string | null;
  responseMessage: string | null;
  responseStatus: 'success' | 'error' | null;
  updateResponseMessage: string | null;
  updateResponseStatus: 'success' | 'error' | null;
  folders: any[] | null;
  folderDetails: any | null;
  items: any[] | null;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  rowsPerPage: number;
  lastFetchedPage: number | null;
  lastFetchedItemId: string | null;
  createdFolderId: string | null;
  setRowsPerPage: (rows: number) => void;
  setFolderName: (name: string) => void;
  createNewFolder: () => Promise<string | null>;
  uploadNewFolder: (parentFolderId: string | null) => Promise<string | null>;
  updateFolder: (folderId: string, updatedData: any) => Promise<void>;
  deleteExpiredFolders: () => Promise<void>;
  listAllFolders: () => Promise<void>;
  listAllFoldersFiles: (page: number, rowsPerPage: number) => Promise<void>;
  copyFolder: (folderId: string, params: { directory_id: string; parent_folder_id: string }) => Promise<void>;
  // restoreFolder: (folderId: string) => Promise<void>;
  // getFolderDetails: (folderId: string) => Promise<void>;
  uploadFolder: (data: { parent_folder_id: string; name: string; directory_id: string; files: File[] }) => Promise<void>;
  downloadFolder: () => Promise<void>;
  deleteFolder: (folderId: string) => Promise<void>;
  emptyTrashFolders: () => Promise<void>;
  resetState: () => void;
  fetchFolders: (page: number) => Promise<void>;
  refeshListAllFoldersFiles: (page: number, rowsPerPage: number) => Promise<void>;

  //second Phase
  isFetchingFolderContent: boolean;
  errorFetchingFolderContent: string | null;
  responseMessageFolderContent: string | null;
  responseStatusFolderContent: 'success' | 'error' | null;
  lastFetchedFolderId: string | null;
  folderPage: number;
  folderRowsPerPage: number;
  selectedFolderId: string | null;        // State for selected folder ID
  folderContents: Item[] | null;
  folderCurrentPage: number;
  folderTotalPages: number;
  folderTotalItems: number;
  lastFetchedFolderPage: number | null;
  permissions: string | null;

  listFolderContents: (page: number) => Promise<void>;
  refreshListFolderContents: (page: number) => Promise<void>;
  callFolderContents: (token: string, folderId: string, page: number, rowsPerPage: number) => Promise<void>;
  setTheSelectedFolderId: (id: string | null) => void;
  clearTheSelectedFolderId: () => void;  // Method to clear folderId
  setFolderRowsPerPage: (value: number) => void;
  setFolderPage: (value: number) => void;

  //sahred with
  listSharedWithFolderContents: (page: number) => Promise<void>;
  refreshListSharedWithFolderContents: (page: number) => Promise<void>;
  callSharedWithFolderContents: (token: string, folderId: string, page: number, rowsPerPage: number) => Promise<void>;

  //shared by
  listSharedByFolderContents: (page: number) => Promise<void>;
  refreshListSharedByFolderContents: (page: number) => Promise<void>;
  callSharedByFolderContents: (token: string, folderId: string, page: number, rowsPerPage: number) => Promise<void>;

  //public share
  isFetchingPublicFolderContent: boolean;
  errorPublicFetchingFolderContent: string | null;
  responseMessagePublicFolderContent: string | null;
  responseStatusPublicFolderContent: 'success' | 'error' | null;
  lastFetchedPublicFolderId: string | null;
  publicFolderPage: number;
  publicFolderRowsPerPage: number;
  selectedPublicFolderId: string | null;        // State for selected folder ID
  publicFolderContents: Item[] | null;
  publicFolderCurrentPage: number;
  publicFolderTotalPages: number;
  publicFolderTotalItems: number;

  listPublicFolderContents: (page: number) => Promise<void>;
  refreshListPublicFolderContents: (folderId: string, page: number, rowsPerPage: number) => Promise<void>;
  setTheSelectedPublicFolderId: (id: string | null) => void;
  clearTheSelectedPublicFolderId: () => void;  // Method to clear folderId

  selectedFolderStack: SelectedFolder[]; // Ensure it's always an array
  pushFolder: (folder_id: string, folder_name: string) => void;
  popFolder: () => void;
  popFolderByIndex: (index: number) => void;
  emptyFolderStack: () => void; // Method to reset the selectedFolderStack

}


const useFolderStore = create<FolderState>((set, get) => ({
  folderName: '',
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isFetching: false,
  isCopying: false,
  isRestoring: false,
  isUploading: false,
  isDownloading: false,
  isEmptyingTrash: false,
  errorCreating: null,
  errorUpdating: null,
  errorDeleting: null,
  errorFetching: null,
  errorCopying: null,
  errorRestoring: null,
  errorUploading: null,
  errorDownloading: null,
  errorEmptyingTrash: null,
  responseMessage: null,
  responseStatus: null,
  updateResponseMessage: null,
  updateResponseStatus: null,
  folders: null,
  folderDetails: null,
  items: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  lastFetchedPage: null,
  rowsPerPage: 10,
  lastFetchedItemId: null,


  //second phase
  isFetchingFolderContent: false,
  errorFetchingFolderContent: null,
  responseMessageFolderContent: null,
  responseStatusFolderContent: null,
  folderPage: 1,
  folderRowsPerPage: 10,
  lastFetchedFolderId: null,
  selectedFolderId: null,             // No selected folder ID by default
  folderContents: null,
  folderCurrentPage: 1,
  folderTotalPages: 1,
  folderTotalItems: 0,
  lastFetchedFolderPage: null,
  permissions: null,

  isFetchingPublicFolderContent: false,
  errorPublicFetchingFolderContent: null,
  responseMessagePublicFolderContent: null,
  responseStatusPublicFolderContent: null,
  lastFetchedPublicFolderId: null,
  publicFolderPage: 1,
  publicFolderRowsPerPage: 10,
  selectedPublicFolderId: null,       // State for selected folder ID
  publicFolderContents: null,
  publicFolderCurrentPage: 1,
  publicFolderTotalPages: 1,
  publicFolderTotalItems: 0,

  selectedFolderStack: [], // Initialize as an empty array, not null
  createdFolderId: "",

  setFolderName: (name: string) => set({ folderName: name }),
  setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
  resetState: () => set({ folderName: '', responseMessage: null, responseStatus: null }),
  setPage: (page: number) => {
    set(() => {
      console.log('Setting page to:', page);
      return { currentPage: page };
    });
  },


  deleteExpiredFolders: async () => {
    set({ isDeleting: true, errorDeleting: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      await deleteExpiredFolders(token);
      set({ responseMessage: 'Expired folders deleted successfully.', responseStatus: 'success', isDeleting: false });
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ errorDeleting: error.message, responseMessage: error.message, responseStatus: 'error', isDeleting: false });
    }
  },

  createNewFolder: async () => {
    set({ isCreating: true, errorCreating: null, responseMessage: null, responseStatus: null });
    console.log('Starting to create a new folder'); // Debugging

    try {
      const folderName = get().folderName;
      const token = useAuthStore.getState().token;
      const selectedItemId = useSidebarStore.getState().selectedItemId;
      const directoryId = useSharedWithFolderStore.getState().directoryId; // Fetching latest folderId

      if (!token) {
        set({ responseMessage: 'Authentication token is missing.', responseStatus: 'error', isCreating: false });
        console.log('No token found'); // Debugging
        return;
      }

      if (!folderName) {
        set({ responseMessage: 'Folder name is required.', responseStatus: 'error', isCreating: false });
        console.log('Folder name is required'); // Debugging
        return;
      }

      const { selectedFolderId } = get(); //parent folder id

      if (!selectedItemId && !directoryId) {
        console.error('Directory ID or Folder ID is not selected');
        return;
      }

      const data = await createFolder(folderName, selectedItemId || directoryId!, selectedFolderId || "", token);
      set({ responseMessage: 'Folder created successfully', responseStatus: 'success', createdFolderId: data.folder_id, isCreating: false });
      console.log('Folder created successfully', data.message); // Debugging
      if (selectedItemId)
        await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
      return data.folder_id;
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to create folder';
      set({ errorCreating: errorMessage, responseMessage: errorMessage, responseStatus: 'error', isCreating: false });
      console.log('Error creating folder', errorMessage); // Debugging
    }
  },

  uploadNewFolder: async (parentFolderId) => {
    set({ isCreating: true, errorCreating: null, responseMessage: null, responseStatus: null });
    console.log('Starting to create a new folder'); // Debugging

    try {
      const folderName = get().folderName;
      const token = useAuthStore.getState().token;
      const selectedItemId = useSidebarStore.getState().selectedItemId;

      if (!token) {
        set({ responseMessage: 'Authentication token is missing.', responseStatus: 'error', isCreating: false });
        console.log('No token found'); // Debugging
        return;
      }

      if (!folderName) {
        set({ responseMessage: 'Folder name is required.', responseStatus: 'error', isCreating: false });
        console.log('Folder name is required'); // Debugging
        return;
      }

      const data = await createFolder(folderName, selectedItemId, parentFolderId || "", token);
      set({ responseMessage: 'Folder created successfully', responseStatus: 'success', createdFolderId: data.folder_id, isCreating: false });
      console.log('Folder created successfully', data.message); // Debugging
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
      return data.folder_id;
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to create folder';
      set({ errorCreating: errorMessage, responseMessage: errorMessage, responseStatus: 'error', isCreating: false });
      console.log('Error creating folder', errorMessage); // Debugging
    }
  },



  updateFolder: async (folderId: string, updatedData: any) => {
    set({ isUpdating: true, errorUpdating: null, updateResponseMessage: '', updateResponseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await updateFolder(folderId, updatedData, token);
      set({ updateResponseMessage: result.message, updateResponseStatus: 'success', isUpdating: false });
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ errorUpdating: error.message, updateResponseMessage: error.message, updateResponseStatus: 'error', isUpdating: false });
    }
  },

  deleteFolder: async (folderId: string) => {
    set({ isDeleting: true, errorDeleting: null, responseMessage: '', responseStatus: null });
    try {
      console.log('deleteFolder');
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      await deleteFolderApi(folderId, token);
      set({ responseMessage: 'Folder deleted successfully.', responseStatus: 'success', isDeleting: false });
      await get().refeshListAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash


    } catch (error: any) {
      set({ errorDeleting: error.message, responseMessage: error.message, responseStatus: 'error', isDeleting: false });

    }

  },

  listAllFolders: async () => {
    set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await listAllFolders(token);
      set({ folders: result.folders, responseMessage: 'Folders retrieved successfully.', responseStatus: 'success', isFetching: false });
    } catch (error: any) {
      set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
    }
  },
  fetchFolders: async (page: number) => {
    const { lastFetchedPage, lastFetchedItemId } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    if (lastFetchedPage === page && lastFetchedItemId === selectedItemId) {
      // console.log(`Page ${page} already fetched for the selected item ${selectedItemId}, skipping...`);
      return;  // Do not fetch if the page has already been fetched for the current selected item
    }

    if (selectedItemId) {
      set({ isFetching: true });
      try {
        const token = useAuthStore.getState().token;
        if (!token) throw new Error('No token found');

        const data = await listAllFoldersFiles(token, selectedItemId, page, get().rowsPerPage);
        set({
          items: data.items,
          totalPages: data.totalPages,
          totalItems: data.totalItems,
          lastFetchedPage: page,  // Update the last fetched page
          lastFetchedItemId: selectedItemId,  // Update the last fetched item ID
          isFetching: false,
        });
      } catch (error) {
        set({ isFetching: false });
        console.error('Error fetching folders:', error);
      }
    }

  },

  refeshListAllFoldersFiles: async (page: number, rowsPerPage: number) => {
    const selectedItemId = useSidebarStore.getState().selectedItemId;

    if (!selectedItemId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }
    
    set({ isFetching: true });
    try {
      const token = useAuthStore.getState().token || '';
      const data = await listAllFoldersFiles(token, selectedItemId, page, rowsPerPage);
      set({
        items: data.items,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
        lastFetchedPage: page,  // Update the last fetched page
        lastFetchedItemId: selectedItemId,  // Update the last fetched item ID
        isFetching: false,
      });
    } catch (error) {
      set({ isFetching: false });
      console.error('Error fetching folders:', error);
    }
  },


  listAllFoldersFiles: async (page: number, rowsPerPage: number) => {
    set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const selectedItemId = useSidebarStore.getState().selectedItemId;

      if (!selectedItemId) {
        console.error('Directory ID or Folder ID is not selected');
        return;
      }

      const result = await listAllFoldersFiles(token, selectedItemId, page, rowsPerPage);
      set({
        items: result.items,
        currentPage: page,
        totalPages: result.totalPages,
        totalItems: result.totalItems,
        responseMessage: 'Folders and files retrieved successfully.',
        responseStatus: 'success',
        isFetching: false,
      });

    } catch (error: any) {
      set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
    }
  },



  copyFolder: async (folderId: string, params: { directory_id: string; parent_folder_id: string }) => {
    set({ isCopying: true, errorCopying: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await copyFolder(folderId, params, token);
      set({ responseMessage: result.message, responseStatus: 'success', isCopying: false });
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ errorCopying: error.message, responseMessage: error.message, responseStatus: 'error', isCopying: false });
    }
  },

  // restoreFolder: async (folderId: string) => {
  //   set({ isRestoring: true, errorRestoring: null, responseMessage: '', responseStatus: null });
  //   try {
  //     const token = useAuthStore.getState().token;
  //     if (!token) throw new Error('No token found');
  //     const result = await restoreFolder(folderId, token);
  //     set({ responseMessage: result.message, responseStatus: 'success', isRestoring: false });
  //     await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
  //   } catch (error: any) {
  //     set({ errorRestoring: error.message, responseMessage: error.message, responseStatus: 'error', isRestoring: false });
  //   }
  // },

  // getFolderDetails: async (folderId: string) => {
  //   set({ isFetching: true, errorFetching: null, responseMessage: '', responseStatus: null });
  //   try {
  //     const token = useAuthStore.getState().token;
  //     if (!token) throw new Error('No token found');

  //     const result = await getFolderDetails(folderId, token);
  //     console.log("folderDetails store responsne: ", result.folder);
  //     set({ folderDetails: result.folder, responseMessage: 'Folder details retrieved successfully.', responseStatus: 'success', isFetching: false });

  //   } catch (error: any) {
  //     set({ errorFetching: error.message, responseMessage: error.message, responseStatus: 'error', isFetching: false });
  //   }
  // },

  uploadFolder: async (data: { parent_folder_id: string; name: string; directory_id: string; files: File[] }) => {
    set({ isUploading: true, errorUploading: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await uploadFolder(data, token);
      set({ responseMessage: result.message, responseStatus: 'success', isUploading: false });
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ errorUploading: error.message, responseMessage: error.message, responseStatus: 'error', isUploading: false });
    }
  },

  downloadFolder: async () => {
    console.log("downloadFolder");
    set({ isDownloading: true, errorDownloading: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');


      set({ responseMessage: 'File downloaded successfully.', responseStatus: 'success', isDownloading: false });

      const result = await downloadFolder("525", token);
      console.log("downloadFolder res: ", result);

      // await get().listAllFoldersFiles(get().currentPage, get().totalItems); // Refresh list
    } catch (error: any) {
      set({ errorDownloading: error.message, responseMessage: error.message, responseStatus: 'error', isDownloading: false });
    }
  },

  emptyTrashFolders: async () => {
    set({ isEmptyingTrash: true, errorEmptyingTrash: null, responseMessage: '', responseStatus: null });
    try {
      const token = useAuthStore.getState().token;
      if (!token) throw new Error('No token found');
      const result = await emptyTrashFolder(token);
      set({ responseMessage: result.message, responseStatus: 'success', isEmptyingTrash: false });
      await get().listAllFoldersFiles(get().currentPage, get().rowsPerPage); // Refresh list
    } catch (error: any) {
      set({ errorEmptyingTrash: error.message, responseMessage: error.message, responseStatus: 'error', isEmptyingTrash: false });
    }
  },

  // second phase ----------------------------------------------------
  listFolderContents: async (folderPage: number) => {
    console.log('listFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { isFetchingFolderContent, lastFetchedFolderId, folderCurrentPage, folderRowsPerPage, selectedFolderId } = get();

    // const selectedFolderId =  useFolderContentStore.getState().folderId;

    if (isFetchingFolderContent || (folderCurrentPage === folderPage && lastFetchedFolderId === selectedFolderId)) {
      return;
    }
    await get().callFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);


  },

  refreshListFolderContents: async (folderPage: number) => {
    console.log('refreshListFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { folderRowsPerPage, selectedFolderId } = get();
    await get().callFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);

  },


  callFolderContents: async (token, folderId, page, rowsPerPage) => {

    try {
      set({ isFetchingFolderContent: true, errorFetchingFolderContent: null, responseMessageFolderContent: '', responseStatusFolderContent: null });

      console.log('callFolderContents store call');

      const result = await listFolderContents(token, folderId, page, rowsPerPage);

      set({
        folderContents: result.items || [],
        folderCurrentPage: result.currentPage || 1,
        folderTotalPages: result.totalPages || 1,
        folderTotalItems: result.totalItems || 1,
        responseMessageFolderContent: 'Folder contents retrieved successfully.',
        responseStatusFolderContent: 'success',
        lastFetchedFolderId: folderId,  // Update the last fetched item ID
        isFetchingFolderContent: false,
      });
    } catch (error: any) {
      set({ errorFetchingFolderContent: error.message, responseMessageFolderContent: error.message, responseStatusFolderContent: 'error', isFetchingFolderContent: false });
    }
  },

  // Set the selected folder ID
  setTheSelectedFolderId: (id) => set({ selectedFolderId: id }),
  clearTheSelectedFolderId: () => set({ selectedFolderId: null }),  // Implementation to clear folderId

  setFolderRowsPerPage: (value: number) => set({ folderRowsPerPage: value }),
  setFolderPage: (value: number) => set({ folderPage: value }),

  //shared with
  listSharedWithFolderContents: async (folderPage: number) => {
    console.log('listSharedWithFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { isFetchingFolderContent, lastFetchedFolderId, folderCurrentPage, folderRowsPerPage, selectedFolderId } = get();

    // const selectedFolderId =  useFolderContentStore.getState().folderId;

    if (isFetchingFolderContent || (folderCurrentPage === folderPage && lastFetchedFolderId === selectedFolderId)) {
      return;
    }
    await get().callSharedWithFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);

  },

  refreshListSharedWithFolderContents: async (folderPage: number) => {
    console.log('refreshListSharedWithFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { folderRowsPerPage, selectedFolderId } = get();
    await get().callSharedWithFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);

  },


  callSharedWithFolderContents: async (token, folderId, page, rowsPerPage) => {

    try {
      set({ isFetchingFolderContent: true, errorFetchingFolderContent: null, responseMessageFolderContent: '', responseStatusFolderContent: null });
      console.log('callFolderContents store call');
      const folderPermissions = useSharedWithFolderStore.getState().folderPermissions;

      const result = await listFolderContents(token, folderId, page, rowsPerPage);

      set({
        folderContents: result.items || [],
        folderCurrentPage: result.currentPage || 1,
        folderTotalPages: result.totalPages || 1,
        folderTotalItems: result.totalItems || 1,
        permissions: folderPermissions,
        responseMessageFolderContent: 'Folder contents retrieved successfully.',
        responseStatusFolderContent: 'success',
        lastFetchedFolderId: folderId,  // Update the last fetched item ID
        isFetchingFolderContent: false,
      });

    } catch (error: any) {
      set({ errorFetchingFolderContent: error.message, responseMessageFolderContent: error.message, responseStatusFolderContent: 'error', isFetchingFolderContent: false });
    }
  },


  //shared by
  listSharedByFolderContents: async (folderPage: number) => {
    console.log('listSharedByFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { isFetchingFolderContent, lastFetchedFolderId, folderCurrentPage, folderRowsPerPage, selectedFolderId } = get();

    // const selectedFolderId =  useFolderContentStore.getState().folderId;

    if (isFetchingFolderContent || (folderCurrentPage === folderPage && lastFetchedFolderId === selectedFolderId)) {
      return;
    }
    await get().callSharedByFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);

  },

  refreshListSharedByFolderContents: async (folderPage: number) => {
    console.log('refreshListSharedByFolderContents store call');

    const token = useAuthStore.getState().token;
    if (!token) throw new Error('No token found');
    const { folderRowsPerPage, selectedFolderId } = get();
    await get().callSharedByFolderContents(token, selectedFolderId || "", folderPage, folderRowsPerPage);

  },


  callSharedByFolderContents: async (token, folderId, page, rowsPerPage) => {

    try {
      set({ isFetchingFolderContent: true, errorFetchingFolderContent: null, responseMessageFolderContent: '', responseStatusFolderContent: null });

      console.log('callSharedByFolderContents store call, folderId' + folderId);

      const result = await listFolderContents(token, folderId, page, rowsPerPage);

      set({
        folderContents: result.items || [],
        folderCurrentPage: result.currentPage || 1,
        folderTotalPages: result.totalPages || 1,
        folderTotalItems: result.totalItems || 1,
        responseMessageFolderContent: 'Folder contents retrieved successfully.',
        responseStatusFolderContent: 'success',
        lastFetchedFolderId: folderId,  // Update the last fetched item ID
        isFetchingFolderContent: false,
      });
    } catch (error: any) {
      set({ errorFetchingFolderContent: error.message, responseMessageFolderContent: error.message, responseStatusFolderContent: 'error', isFetchingFolderContent: false });
    }
  },


  //publich share 

  listPublicFolderContents: async (folderPage: number) => {
    console.log('listPublicFolderContents store call');

    const { isFetchingPublicFolderContent, lastFetchedPublicFolderId, publicFolderCurrentPage, publicFolderRowsPerPage, selectedPublicFolderId } = get();

    // const selectedFolderId =  useFolderContentStore.getState().folderId;

    if (isFetchingPublicFolderContent || (publicFolderCurrentPage === folderPage && lastFetchedPublicFolderId === selectedPublicFolderId)) {
      return;
    }

    await get().refreshListPublicFolderContents(selectedPublicFolderId || "", folderPage, publicFolderRowsPerPage);

  },

  refreshListPublicFolderContents: async (folderId, page, rowsPerPage) => {

    try {
      set({ isFetchingPublicFolderContent: true, errorPublicFetchingFolderContent: null, responseMessagePublicFolderContent: '', responseStatusPublicFolderContent: null });

      console.log('refreshListPublicFolderContents store call, folderId' + folderId);

      const result = await listPublicFolderContents(folderId, page, rowsPerPage);

      set({
        publicFolderContents: result.items || [],
        publicFolderCurrentPage: result.currentPage || 1,
        publicFolderTotalPages: result.totalPages || 1,
        publicFolderTotalItems: result.totalItems || 1,
        responseMessagePublicFolderContent: 'Folder contents retrieved successfully.',
        responseStatusPublicFolderContent: 'success',
        lastFetchedPublicFolderId: folderId,  // Update the last fetched item ID
        isFetchingPublicFolderContent: false,
      });
    } catch (error: any) {
      set({ errorPublicFetchingFolderContent: error.message, responseMessagePublicFolderContent: error.message, responseStatusPublicFolderContent: 'error', isFetchingPublicFolderContent: false });
    }
  },

  setTheSelectedPublicFolderId: (id) => set({ selectedPublicFolderId: id }),
  clearTheSelectedPublicFolderId: () => set({ selectedPublicFolderId: null }),  // Implementation to clear folderId

  // Push a new folder onto the stack
  pushFolder: (folder_id, folder_name) => {
    set((state) => ({
      selectedFolderStack: [
        ...state.selectedFolderStack,
        { folder_id, folder_name } // Push the folder object
      ],
    }));
  },

  // Pop the last folder from the stack
  popFolder: () => {
    set((state) => ({
      selectedFolderStack: state.selectedFolderStack.slice(0, -1), // Remove the last folder
    }));
  },


  popFolderByIndex: (index) => {
    set((state) => ({
      selectedFolderStack: state.selectedFolderStack.slice(0, index + 1), // Remove the last folder
    }));
  },

  // Reset the selectedFolderStack to an empty array
  emptyFolderStack: () => {
    set({ selectedFolderStack: [] }); // Set it to an empty array
  },



}));

export default useFolderStore;
