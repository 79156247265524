import { create } from 'zustand';
import { useAuthStore } from './useAuthStore';
import { signIn, verifyGoogleAuth } from 'src/services/api/API';
export interface ResponsSignIn {
  message?: string;
  success?: boolean;
  temp_token?: string;
image_google_auth_url?: string;

}
// export interface ResponsVerifyGoogleAuth {
//   message?: string;
//   success?: boolean;
// token?: string;


// }
interface SignInState {
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
  phoneNumber: string;
  password: string;
  googleAuthCode: string;
  newPassword: string;
  confirmPassword: string;
  step: number;
  tempToken: string | null;
  imageGoogleAuthUrl: string | null;

  setPhoneNumber: (phoneNumber: string) => void;
  setPassword: (password: string) => void;
  setGoogleAuthCode: (googleAuthCode: string) => void;
  setNewPassword: (newPassword: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  setStep: (step: number) => void;
  setTempToken: (tempToken: string | null) => void;
  setImageGoogleAuthUrl: (imageGoogleAuthUrl: string | null) => void;
  resetState: () => void;

  handleSignIn: () => Promise<void>;
  handleGoogleAuthSubmit: (navigate: (path: string) => void) => Promise<void>;
  handleResetPassword: () => Promise<void>;
}

export const useSignInStore = create<SignInState>((set) => ({
  isLoading: false,
  error: null,
  successMessage: null,
  phoneNumber: '',
  password: '',
  googleAuthCode: '',
  newPassword: '',
  confirmPassword: '',
  step: 1,
  tempToken: null,
  imageGoogleAuthUrl: null,

  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  setPassword: (password) => set({ password }),
  setGoogleAuthCode: (googleAuthCode) => set({ googleAuthCode }),
  setNewPassword: (newPassword) => set({ newPassword }),
  setConfirmPassword: (confirmPassword) => set({ confirmPassword }),
  setStep: (step) => set({ step }),
  setTempToken: (tempToken) => set({ tempToken }),
  setImageGoogleAuthUrl: (imageGoogleAuthUrl) => set({ imageGoogleAuthUrl }),

  resetState: () => set({
    isLoading: false,
    error: null,
    successMessage: null,
    phoneNumber: '',
    password: '',
    googleAuthCode: '',
    newPassword: '',
    confirmPassword: '',
    step: 1,
    tempToken: null,
    imageGoogleAuthUrl: null,
  }),

  handleSignIn: async () => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
       const { phoneNumber, password } = useSignInStore.getState();
      const result = await signIn(phoneNumber, password);
      if (result.success) {
        set({
          tempToken: result.temp_token,
          imageGoogleAuthUrl: result.image_google_auth_url,
          step: 2,
          isLoading: false,
          successMessage: '',
        });
      } else {
        set({
          error: document.documentElement.dir === "rtl" ? "الرقم التعريفي أو كلمة المرور غير صحيحة": "Invalid ID or password"  , // Custom error message
          isLoading: false,
        });
      }
    } catch (error: any) {
      set({ error: document.documentElement.dir === "rtl" ? "الرقم التعريفي أو كلمة المرور غير صحيحة": "Invalid ID or password" , isLoading: false }); // Show this error regardless of the actual error
    }
  },


  handleGoogleAuthSubmit: async (navigate) => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      const { googleAuthCode, tempToken } = useSignInStore.getState();
      const { setToken ,setPermissions } = useAuthStore.getState();
      if (!tempToken) throw new Error('No temporary token found');
      const result = await verifyGoogleAuth(googleAuthCode, tempToken);
      console.log("resulthessah");
      console.log(result);
      console.log("resulthessah");
      if (result.success) {
        setToken(result.token || "");
        setPermissions(result.permissions || {}, result.name_en || ""); 
        set({
          successMessage: 'Google Authenticator verification successful',
          step: 3,
          isLoading: false,
        });
        useSignInStore.getState().resetState();
        navigate('/Shared');
      } else {
        set({
          error: 'Incorrect code', // Custom error message for 2FA
          isLoading: false,
        });
      }
    } catch (error: any) {
      set({ error: 'Incorrect code', isLoading: false }); // Show this error regardless of the actual error
    }
  },

  handleResetPassword: async () => {
    set({ isLoading: true, error: null, successMessage: null });
    try {
      // Implement your password reset logic here
      set({
        successMessage: 'Password reset successful',
        isLoading: false,
      });
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
    }
  },

}));
