import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserResources } from 'src/hooks/useUserResourcesHooks';

const StorageProgress: React.FC = (get) => {
  const { userResources, getResources, isLoading, errorUserResourceState } = useUserResources();
  const { t } = useTranslation();

  useEffect(() => {
    getResources();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errorUserResourceState) {
    return <div>Error: {errorUserResourceState}</div>;
  }

  if (!userResources) {
    return null;
  }

  const { storage_limit, consumed_storage, trash_storage } = userResources;
  const total_used_storage = consumed_storage + trash_storage;
  const free_space = storage_limit - total_used_storage;
  const calculatePercentage = (value: number, total: number) => (total === 0 ? 0 : (value / total) * 100);

  const consumedPercentage = calculatePercentage(consumed_storage, storage_limit);
  const trashPercentage = calculatePercentage(trash_storage, storage_limit);
  const freeSpacePercentage = calculatePercentage(free_space, storage_limit);

  return (
    <div className="p-4">
      <div className="mb-4">
        <div className="flex justify-between mb-1">
          <span className="text-sm font-medium text-gray-700">{t("TotalUsedStorage")} </span>
          <span className="text-sm font-medium text-gray-700">{calculatePercentage(total_used_storage, storage_limit).toFixed(2)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 relative">
          <div
            className="h-2.5 rounded-l-full"
            style={{ width: `${trashPercentage}%`, backgroundColor: '#FF0000', position: 'absolute', left: 0 }}
          ></div>
          <div
            className={`h-2.5 ${trashPercentage > 0 ? '' : 'rounded-l-full'}`}
            style={{
              width: `${consumedPercentage}%`,
              backgroundColor: '#2A835F',
              position: 'absolute',
              left: `${trashPercentage}%`,
            }}
          ></div>
          <div
            className="h-2.5 rounded-r-full"
            style={{ width: `${freeSpacePercentage}%`, position: 'absolute', left: `${trashPercentage + consumedPercentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default StorageProgress;
