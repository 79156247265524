// DeleteFileModal.tsx
import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import useFilesStore from "src/store/layout/useFilesStore";
import useRefreshTrash from "src/hooks/useRefreshTrash";
import useRefreshListAllSharedByMe from "src/hooks/useRefreshListAllSharedByMe";
import useRefreshListAllFolders from "src/hooks/useRefreshListAllFolders";
import useRefreshListFolderContents from 'src/hooks/useRefreshListFolderAllContents';
import useRefreshListSharedByFolderContents from "src/hooks/useRefreshListSharedByFolderAllContents";
import useRefreshListSharedWithFolderContents from "src/hooks/useRefreshListSharedWithFolderAllContents";
import useRefreshListAllSharedWithMe from "src/hooks/useRefreshListAllSharedWithMe";
import useFavoriteStore from "src/store/api/useFavoriteStore";

interface DeleteFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  fileType: string;
}

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({ isOpen, onClose, fileId, fileType }) => {
  const { t } = useTranslation();
  const deleteFile = useFilesStore((state) => state.deleteFile);
  const { refreshListFolderContents, folderCurrentPage } = useRefreshListFolderContents();
  const { refreshListSharedByFolderContents, folderCurrentPage: sharedByFolderCurrentPage } = useRefreshListSharedByFolderContents();
   const { fetchTrashedItems,  currentPage: TrashCurrentPage, rowsPerPage: TrashRowsPerPage } = useRefreshTrash();
   const { refreshListSharedWithFolderContents, folderCurrentPage: sharedWithFolderCurrentPage } = useRefreshListSharedWithFolderContents();
   const { refreshListAllShareByUser, shareByCurrentPage, rowsPerPage: sharedByRowsPerPage } = useRefreshListAllSharedByMe();
   const { refreshListAllShareWithUser, shareWithCurrentPage, rowsPerPage: sharedWithRowsPerPage } = useRefreshListAllSharedWithMe();
   const { refeshListAllFoldersFiles, currentPage, rowsPerPage: homeRowsPerPage } = useRefreshListAllFolders();
   const { page, rowsPerPage, reftreshFetchFavoritesWithoutDirectory } = useFavoriteStore();

  const handleDelete = async () => {

    await deleteFile(fileId, fileType);

    if (fileType !== 'sharedWithFile'){
      await refeshListAllFoldersFiles(currentPage, homeRowsPerPage);
      await refreshListFolderContents(folderCurrentPage);
      await refreshListAllShareByUser(shareByCurrentPage, sharedByRowsPerPage);
      await refreshListSharedByFolderContents(sharedByFolderCurrentPage);
     }
     else  {
      await refreshListAllShareWithUser(shareWithCurrentPage, sharedWithRowsPerPage);
       await refreshListSharedWithFolderContents(sharedWithFolderCurrentPage);
    }
    await fetchTrashedItems(TrashCurrentPage, TrashRowsPerPage);
    await reftreshFetchFavoritesWithoutDirectory(page, rowsPerPage);

    onClose(); // Close the modal after successful deletion
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          {t("DeleteFile")}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <h2>{t('AreYouSureToDelete')}</h2>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" variant="light" onPress={onClose}>
            {t("No")}
          </Button>
          <Button color="danger" onPress={handleDelete}>
            {t("Yes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFileModal;