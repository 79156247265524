import { create } from 'zustand';
import axios from 'axios';
import { uploadFiles } from 'src/services/api/API';
import { FileStore } from 'src/types/api/UploudFiles';
import { useSidebarStore } from './useSidebarStore';
import { useFolderContentStore } from 'src/components/main_page/cards/FolderCard';
import { useSharedWithFolderStore } from 'src/components/shared_files/cards/SharedWithMeFolderCard';
import { useSharedByFolderStore } from 'src/components/shared_files/cards/SharedByMeFolderCard';
import useUserResourceStore from '../api/useUserResourceStore';
import useFolderStore from '../api/useFolderStore';
import { useAlertStore } from 'src/components/common/CustomAlert';

export const useUploadFileStore = create<FileStore>((set, get) => ({
  files: [],
  uploading: false,
  description: '',
  uploadResponseStatus: null,

  setFiles: (files) => set({ files }),
  setUploading: (uploading) => set({ uploading }),
  setDescription: (description) => set({ description }),

  setUploadResponseStatus: (status) => set({ uploadResponseStatus: status }),

  addFiles: (newFiles) => set((state) => ({ files: [...state.files, ...newFiles] })),

  updateFileProgress: (id, progress) => set((state) => ({
    files: state.files.map((file) =>
      file.id === id ? { ...file, progress } : file
    ),
  })),

  removeFile: (id) => set((state) => ({
    files: state.files.filter((file) => {
      if (file.id === id) {
        file.cancelTokenSource.cancel('Upload canceled by user');
        return false;
      }
      return true;
    }),
  })),

  clearFiles: () => set({ files: [] }),

  uploadFiles: async (token: string, description: string) => {
    console.log("uploadFiles call");
    const { files, updateFileProgress, clearFiles, setUploading, setDescription, uploadResponseStatus, setUploadResponseStatus } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    const folderId = useFolderContentStore.getState().folderId; // Fetching latest folderId
    setUploadResponseStatus(null);

    if (!selectedItemId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }

    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {


        // const folderIdToUse = fileWithProgress.path
        //   ? fileWithProgress.path.includes("/") || fileWithProgress.path === "ـ"
        //     ? fileWithProgress.folderId ?? folderId
        //     : fileWithProgress.path === "_"
        //       ? fileWithProgress.folderId ?? folderId
        //       : null
        //   : null;

          // console.log("folderId: ",folderId);
          // console.log("folderIdToUse: ",fileWithProgress.folderId );

        const result = await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId,
          // folderIdToUse || folderId || null, // Use the determined folderIdToUse
          fileWithProgress.folderId ?? folderId ?? null,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );

      }));

      if (files.length > 0)
        setUploadResponseStatus('success');
      else
        setUploadResponseStatus(null);


      clearFiles();
      setDescription('');

      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash

    } catch (error) {
      setUploadResponseStatus('error');

      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);

      // Ensure `uploadResponseStatus` is properly used
      const status = get().uploadResponseStatus; // Retrieve the latest value of uploadResponseStatus
      if (status) {
        useAlertStore.getState().showAlert(
          status === "success"
            ? "FileUploadedSuccessfully"
            : "FailedUploadingFiles",
          status === "success" ? "success" : "error"
        );
      }
    }
  },


  uploadSharedByFiles: async (token: string, description: string) => {
    const { files, updateFileProgress, clearFiles, setUploading, setDescription, uploadResponseStatus, setUploadResponseStatus } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    let folderId = useSharedByFolderStore.getState().folderId; // Fetching latest folderId
    const selectedFolderId = useFolderStore.getState().selectedFolderId;
    if (folderId !== selectedFolderId)
      folderId = selectedFolderId;

    setUploadResponseStatus(null);

    if (!selectedItemId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }

    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {
        const result = await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId,
          fileWithProgress.folderId ?? folderId,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );
      }));
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash

      clearFiles();
      setDescription('');
      setUploadResponseStatus('success');

    } catch (error) {
      setUploadResponseStatus('error');

      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);

      // Ensure `uploadResponseStatus` is properly used
      const status = get().uploadResponseStatus; // Retrieve the latest value of uploadResponseStatus
      if (status) {
        useAlertStore.getState().showAlert(
          status === "success"
            ? "FileUploadedSuccessfully"
            : "FailedUploadingFiles",
          status === "success" ? "success" : "error"
        );
      }
    }
  },

  uploadSharedWithFiles: async (token: string, description: string) => {
    const { files, updateFileProgress, clearFiles, setUploading, setDescription, uploadResponseStatus, setUploadResponseStatus } = get();
    const selectedItemId = useSidebarStore.getState().selectedItemId; // Fetching latest selectedItemId
    let folderId = useSharedWithFolderStore.getState().folderId; // Fetching latest folderId
    const directoryId = useSharedWithFolderStore.getState().directoryId; // Fetching latest folderId
    const selectedFolderId = useFolderStore.getState().selectedFolderId;
    if (folderId !== selectedFolderId)
      folderId = selectedFolderId;

    setUploadResponseStatus(null);

    if (!selectedItemId && !directoryId) {
      console.error('Directory ID or Folder ID is not selected');
      return;
    }

    setUploading(true);

    try {
      await Promise.all(files.map(async (fileWithProgress) => {
        const result = await uploadFiles(
          fileWithProgress.file,
          description,
          token,
          selectedItemId || directoryId!,
          fileWithProgress.folderId ?? folderId,
          (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateFileProgress(fileWithProgress.id, progress);
            }
          },
          fileWithProgress.cancelTokenSource.token
        );
      }));
      
      const { clearHasFetched, getUserResources } = useUserResourceStore.getState(); // Access getUserResources from another store
      clearHasFetched();
      await getUserResources(); // Refresh user resources after emptying the trash

      clearFiles();
      setDescription('');
      setUploadResponseStatus('success');

    } catch (error) {
      setUploadResponseStatus('error');

      if (axios.isCancel(error)) {
      } else {
        console.error("Upload error:", error);
      }
    } finally {
      setUploading(false);

      // Ensure `uploadResponseStatus` is properly used
      const status = get().uploadResponseStatus; // Retrieve the latest value of uploadResponseStatus
      if (status) {
        useAlertStore.getState().showAlert(
          status === "success"
            ? "FileUploadedSuccessfully"
            : "FailedUploadingFiles",
          status === "success" ? "success" : "error"
        );
      }
    }
  },

}));
